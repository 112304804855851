<template>

  <div class="row">
    <div class="col-md-3">
      <ul class="nav nav-pills flex-column nav-left">
        <li class="nav-item">
          <router-link
            :to="{ name: 'jabatan' }"
            class="nav-link"
            :class="($route.meta.pageActive == 'jabatan')?'active':''"
          >
            <feather-icon
              icon="UserCheckIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Jenis Jabatan</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :to="{ name: 'dokumen' }"
            :class="($route.meta.pageActive == 'dokumen')?'active':''"
          >
            <feather-icon
              icon="CheckSquareIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Dokumen</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :to="{ name: 'kepemilikan' }"
            :class="($route.meta.pageActive == 'kepemilikan')?'active':''"
          >
            <feather-icon
              icon="FlagIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Jenis Kepemilikan</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="col-md-9">
      <router-view />
    </div>
  </div>
 
</template>

<script>
export default {
  data() {
    return {
      options: {},
    }
  },
  
}
</script>
